enum LanguageDir {
  RTL = "rtl",
  LTR = "ltr",
}

function getLanguageDir() {
  return document.documentElement.getAttribute("dir");
}

export function isRTL(): boolean {
  return getLanguageDir() === LanguageDir.RTL;
}

export function isLTR(): boolean {
  return getLanguageDir() === LanguageDir.LTR;
}
