import { Icon } from "@livingmap/core-ui-v2";
import { useState } from "react";
import RouteStepBanner from "../../../../components/RouteStepBanner/RouteStepBanner";
import { useGetRouteMilestonesProps } from "../../../../hooks/useGetRouteMilestonesProps";
import { RouteMilestoneFeature } from "../../../../redux/services/types";
import RouteStepSeparator from "../RouteStepSeparator/RouteStepSeparator";
import styles from "./RouteContent.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  routeMilestones: RouteMilestoneFeature[];
  totalTime: number;
  showTime?: boolean;
}

export default function RouteContent({
  routeMilestones,
  totalTime,
  showTime,
}: Props) {
  const { t } = useTranslation();

  const [displaySteps, setDisplaySteps] = useState(false);

  const handleOnClick = () => {
    setDisplaySteps(!displaySteps);
  };

  const routeMilestonesProps = useGetRouteMilestonesProps({
    routeMilestones,
    totalTime,
    displaySteps,
  });

  routeMilestonesProps.splice(1, 0, {
    accent: false,
    text: "show/hide",
    subText: "",
    icon: "WalkingPersonIcon",
    time: "",
  });

  const children = routeMilestonesProps.map((props, index) => {
    if (props.text === "show/hide") {
      return <RouteStepSeparator displaySteps onClick={handleOnClick} />;
    } else {
      return (
        <RouteStepBanner
          {...props}
          className={styles.milestone}
          dataQA="route-step-banner-item"
          key={index}
          time={showTime ? props.time : undefined}
        />
      );
    }
  });

  return (
    <>
      <div className={styles.warning}>
        <Icon
          type="WarningIcon"
          dataQA="warning-icon"
          className={styles.icon}
        />
        <p className={styles.text}>{t("navigate_page.caution_message")}</p>
      </div>
      <div className={styles.routeMilestones}>{children}</div>
    </>
  );
}
