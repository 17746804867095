import { Path } from "./../../../../utils/types";
import LivingMap, { LMFeature } from "@livingmap/core-mapping";
import { MapMouseEvent } from "mapbox-gl";
import InteractionControl from "../interaction-control";
import FloorControl from "../floor-control";
import { UserMapAction } from "./user-map-action";
import { SourceIds, PLUGIN_IDS } from "../types/index";
import { store } from "../../../../redux/store";
import { replace } from "../../../../utils/navigate";
import { clearLocation } from "../../../../redux/slices/applicationSlice";
import { matchPath } from "react-router-dom";
import { QueryParams, RoutingPath } from "../../../../utils/types";

/**
 * Class representing a handler of a User interaction with the map canvas.
 * This class implements the Living Map handler of a map click.
 * @implements {UserMapAction<MouseEvent>}
 */
class UserClickAction implements UserMapAction {
  private LMMap: LivingMap;
  private interactionControl!: InteractionControl;
  private floorControl!: FloorControl;

  constructor(LMMap: LivingMap) {
    this.LMMap = LMMap;
    this.handle = this.handle.bind(this);
  }

  /**
   * Given a Mapbox MouseMove event this handler will deal with the response according to the
   * LivingMap business rules around mousemovents.
   * @param  {MapMouseEvent} clickEvent
   * @returns void
   */
  public handle(clickEvent: MapMouseEvent): void {
    // get the saved location from search from the store
    const {
      application: { savedLocation, queryParamsConfig },
      router,
    } = store.getState();

    if (queryParamsConfig["ui-gestures"] === "disable") return;

    const features = this.LMMap.queryRenderedFeatures(clickEvent.point);

    const clusterFeatures = features.filter((feature) => {
      return feature.isClusterable();
    });

    if (clusterFeatures.length > 0) {
      this.handleClusterZoom(clusterFeatures[0]);
      return;
    }

    this.interactionControl = this.LMMap.getPluginById<InteractionControl>(
      PLUGIN_IDS.INTERACTION,
    );
    const lmFeatureGeoJSON = features.find((feature) =>
      feature.getHasAttributes(),
    );

    if (!lmFeatureGeoJSON) {
      this.handleOtherRouteSelection(features);

      const dispatch = store.dispatch;

      const path = router?.location?.pathname || "";

      if (matchPath(`/${RoutingPath.ROUTE}/*`, path)) return;

      if (matchPath(`/${RoutingPath.NAVIGATE}/*`, path)) {
        dispatch(clearLocation());
        return;
      }

      dispatch(
        replace({
          pathOrLocation:
            savedLocation && savedLocation.pathname !== `/${Path.FEATURE}`
              ? savedLocation
              : "/",
          discardParams: [QueryParams.FEATURE_ID],
          ...(savedLocation &&
            savedLocation.state && { state: savedLocation.state }),
        }),
      );

      return this.interactionControl.deselectFeatures();
    }

    this.interactionControl.selectFeature(lmFeatureGeoJSON);
  }

  private handleClusterZoom(clusterFeature: any): void {
    const mapInstance = this.LMMap.getMapboxMap()!;
    const clusterSource = mapInstance.getSource(
      SourceIds.CLUSTER_SOURCE_ID,
    ) as mapboxgl.GeoJSONSource;
    clusterSource.getClusterExpansionZoom(
      clusterFeature.getMapboxFeature().properties.cluster_id,
      (err, zoom) => {
        if (err) {
          console.error(err);
          return;
        }

        // Check if zoom is null or undefined before proceeding
        if (zoom == null) {
          console.error("Zoom level is null or undefined.");
          return;
        }

        mapInstance.easeTo({
          center: clusterFeature.getCentroid(),
          zoom,
        });
      },
    );
  }

  private handleOtherRouteSelection(features: LMFeature[]) {
    this.floorControl = this.LMMap.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );

    const currentFloor = this.floorControl.getActiveFloor();
    if (currentFloor) {
      const selectedRouteFloors = features
        .filter((feature) => {
          return (
            feature.getSource() === "route-line-other_floor" &&
            feature.getFloorId() !== currentFloor.id
          );
        })
        .map((feature) => feature.getFloorId())
        .filter((value, index, self) => self.indexOf(value) === index);
      if (selectedRouteFloors.length === 1) {
        const allFloors = this.floorControl.getAllFloors();
        const selected = allFloors?.filter(
          (floor) => floor.id === selectedRouteFloors[0],
        );
        if (selected) {
          this.floorControl.setActiveFloor(selected[0]);
        }
      }
    }
  }
}

export default UserClickAction;
