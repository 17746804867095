import { useParams } from "react-router-dom";
import { IconType } from "@livingmap/core-ui-v2";
import { addMinutes, format } from "date-fns";

import { RouteMilestoneFeature } from "./../redux/services/types/index";
import { useMatchLocationCoords } from "./useMatchLocationCoords";

import { formatLatLng } from "../utils/formatLatLng";
import i18n from "../i18next";
import { useTranslation } from "react-i18next";

interface Props {
  routeMilestones: RouteMilestoneFeature[];
  totalTime?: number;
  displaySteps: boolean;
}

const getSubText = (type: string, direction?: string) => {
  switch (type) {
    case "floor_change":
      if (direction === "up") return i18n.t("navigate_page.go_up");
      else return i18n.t("navigate_page.go_down");
    case "route_start":
      return i18n.t("navigate_page.start_at");
    case "route_end":
      return i18n.t("navigate_page.arrive_at");
    default:
      return i18n.t("navigate_page.navigate_default");
  }
};

const getIcon = (mapIcon: string): IconType => {
  switch (mapIcon) {
    case "route_stairs_go_up":
    case "route_stairs_go_down":
      return "StairsIcon";
    case "route_lift_go_up":
    case "route_lift_go_down":
      return "LiftIcon";
    case "route_escalator_go_up":
    case "route_escalator_go_down":
      return "EscalatorIcon";
    case "route_start":
      return "NearMeTopIcon";
    case "route_end":
      return "LocationIcon";
    default:
      return "WalkingPersonIcon";
  }
};

export const useGetRouteMilestonesProps = ({
  routeMilestones,
  totalTime = 0,
  displaySteps,
}: Props) => {
  const { fromId, toId } = useParams();
  const { t } = useTranslation();

  const { isMatchWithLocationCoords } = useMatchLocationCoords();

  const fromCoords = fromId?.split(",");
  const toCoords = toId?.split(",");

  return routeMilestones
    .filter(({ properties: { type } }) => {
      if (type === "route_start" || type === "route_end") {
        return true;
      } else {
        return displaySteps;
      }
    })
    .map(
      (
        {
          properties: { descriptionText, mapIcon, type, direction },
          geometry: { coordinates },
        },
        index,
      ) => {
        let coords: string[] | undefined;
        if (descriptionText === "Start") coords = fromCoords;
        else if (descriptionText === "End") coords = toCoords;
        const accent = routeMilestones.length === index + 1 || index === 0;
        const text = isMatchWithLocationCoords(coords)
          ? t("route_page.your_location_button")
          : coords?.length === 2
            ? formatLatLng(coordinates)
            : descriptionText;
        const subText =
          descriptionText === "Start" ? "" : getSubText(type, direction);
        const icon =
          descriptionText === "Start" ? "NearMeIcon" : getIcon(mapIcon);

        const time =
          routeMilestones.length === index + 1
            ? format(addMinutes(new Date(), totalTime), "HH:mm")
            : ""; // TODO: '' to be time between milestones in the future

        return {
          accent,
          text,
          subText,
          icon,
          time,
        };
      },
    );
};
