import { Button, Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import { addMinutes, format } from "date-fns";

import { DisplayUnitSystem } from "../../redux/services/types";

import { isLTR, isRTL } from "../../utils/getLanguageDir";

import styles from "./TimeToDestination.module.scss";

interface Props {
  className?: string;
  minutes: number;
  kilometres: number;
  displayUnit: DisplayUnitSystem;
  steps: number;
  currentStep: number;
  live?: boolean;
  onClick: () => void;
  dataQA: string;
  arrived?: boolean;
  skipGo?: boolean;
  showGoButton?: boolean;
  showTime?: boolean;
}

export default function TimeToDestination({
  minutes,
  kilometres,
  displayUnit,
  onClick,
  live,
  steps,
  currentStep,
  dataQA,
  arrived,
  skipGo,
  showGoButton = true,
  className,
  showTime,
}: Props) {
  const btnText = currentStep === 0 && !skipGo ? "GO" : "End";
  const eta = format(addMinutes(new Date(), minutes), "HH:mm");

  const getTotalTime = () => {
    if (minutes < 1) return "<1 min";

    const mins = Math.round(minutes);

    if (mins >= 60) {
      const hours = Math.floor(mins / 60);
      const minutes = mins % 60;

      const totalTime =
        minutes > 0 ? `${hours} hr ${minutes} min` : `${hours} hr`;
      return totalTime;
    } else return `${mins} min`;
  };

  const getDistance = () => {
    if (displayUnit === "imperial") {
      const feet = Math.round(kilometres * 3280.84);
      // If less than 0.2 miles, show feet
      if (feet <= 1056) return `${feet} ft`;
      else {
        // If more than 0.2 miles, convert and show miles
        const miles = (kilometres * 0.621371).toFixed(1);
        return `${miles} mi`;
      }
    } else {
      // If less than 1km, show meters
      const meters = Math.round(kilometres * 1000);
      if (meters < 1) return "<1 m";
      if (meters < 1000) return `${meters} m`;
      else return `${kilometres.toFixed(1)} km`;
    }
  };

  return (
    <div className={classNames(styles.container, className)} data-qa={dataQA}>
      <div>
        {live && (
          <Icon
            dataQA="live-icon"
            type="LiveIcon"
            className={styles.liveIcon}
          />
        )}
        {arrived ? (
          <span className={styles.text}>You have arrived</span>
        ) : (
          <>
            {showTime && <span className={styles.text}>{getTotalTime()}</span>}
            <span
              className={classNames(styles.subText, { [styles.rtl]: isRTL() })}
            >
              {!showTime
                ? getDistance()
                : isLTR()
                  ? ` · ${getDistance()} · ${eta}`
                  : ` ${eta} · ${getDistance()} · `}
            </span>
          </>
        )}
      </div>
      {showGoButton && (
        <Button
          dataQA={`${btnText}-button`}
          onClick={onClick}
          rounded
          color={
            currentStep === 0 && !skipGo
              ? "blue"
              : currentStep !== steps - 1
                ? "red"
                : "green"
          }
          {...(currentStep === steps - 1 && { icon: "CheckIcon" })}
        >
          {btnText}
        </Button>
      )}
    </div>
  );
}
