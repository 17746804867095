import { useState, FC } from "react";
import styles from "./RouteStepSeparator.module.scss";

interface RouteStepSeparatorProps {
  displaySteps: boolean;
  onClick: () => void;
}

const RouteStepSeparator: FC<RouteStepSeparatorProps> = ({
  displaySteps,
  onClick,
}) => {
  const [labelState, setLabelState] = useState(displaySteps);
  const option = labelState ? "Show" : "Hide";

  const handleOnClick = () => {
    setLabelState(!labelState);
    onClick();
  };

  return (
    <div onClick={handleOnClick} className={styles.separator}>
      <div className={styles.line}>&nbsp;</div>
      <span className={styles.text}>{option} Steps</span>
    </div>
  );
};

export default RouteStepSeparator;
